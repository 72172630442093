<div class="">
    <div class="header">
        <span>Log Activity</span>
        <button type="button" (click)="close()"><i class="fas fa-close text-gray-500 text-lg"></i></button>
    </div>
    <div class="content_log">
        <form [formGroup]="form">
            <div class="info">
                <div class="customer">
                    <p class="customer_name">{{contant?.name}}</p>
                    <div class="customer_contacts">
                        @if (contant?.phones?.length) {
                            @for(phone of contant?.phones; track $index) {
                                @if (phone?.value) {
                                    <div class="customer_contac_item">
                                        <input (change)="onPhoneCheckboxChange($event, phone?.value)" [id]="phone?.value" type="checkbox" [checked]="form.get('phones')?.value.includes(phone?.value)" class="custom-checkbox text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label [for]="phone?.value">{{applyMask(phone?.value, inputMasks.phone)}}</label>
                                    </div>
                                }
                            }
                        }

                        @if (contant?.emails?.length) {
                            @for(email of contant?.emails; track $index) {
                                @if (email?.value) {
                                    <div class="customer_contac_item">
                                        <input (change)="onEmailCheckboxChange($event, email?.value)" [id]="email?.value" [checked]="form.get('emails')?.value.includes(email?.value)" type="checkbox" class="custom-checkbox text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label [for]="email?.value">{{truncateString(email?.value)}}</label>
                                    </div>
                                }
                            }
                        }
                    </div>
                    @if (
                    (emailsPhonesWarning && form.get('emails')?.invalid && form.get('phones')?.invalid) ||
                    (form.get('types')?.value?.includes('Email') && !form.get('emails')?.value?.length) ||
                    (form.get('types')?.value?.includes('SMS') && !form.get('phones')?.value?.length) ||
                    (form.get('types')?.value?.includes('Call') && !form.get('phones')?.value?.length)
                    ) {
                        <p class="please_select">Please select email / phone</p>
                    }
                </div>
                <div class="select_cont">
                    <div class="select_cont_item">
                        <div class="outcomes">
                            @for(tag of outcomeGreen; track $index) {
                                <button 
                                    class="outcome_item" 
                                    (click)="onChangeOutcome(tag?.name)" 
                                    [ngStyle]="{'color:': tag?.color, 'background': this.form.get('subject').value !== tag?.name ? tag?.bg : null}" 
                                    [ngClass]="{'active_outcome_green': this.form.get('subject').value == tag?.name}"
                                >
                                    {{tag?.name}}
                                </button>
                            }
                        </div>
                        <div class="outcomes">
                            @for(tag of outcomeYellow; track $index) {
                                <button 
                                    class="outcome_item" 
                                    (click)="onChangeOutcome(tag?.name)" 
                                    [ngStyle]="{'color:': tag?.color, 'background': this.form.get('subject').value !== tag?.name ? tag?.bg : null}" 
                                    [ngClass]="{'active_outcome_yellow': this.form.get('subject').value == tag?.name}"
                                >
                                    {{tag?.name}}
                                </button>
                            }
                        </div>
                        <div class="outcomes">
                            @for(tag of outcomeRed; track $index) {
                                <button 
                                    class="outcome_item" 
                                    (click)="onChangeOutcome(tag?.name)" 
                                    [ngStyle]="{'color:': tag?.color, 'background': this.form.get('subject').value !== tag?.name ? tag?.bg : null}" 
                                    [ngClass]="{'active_outcome_red': this.form.get('subject').value == tag?.name}"
                                >
                                    {{tag?.name}}
                                </button>
                            }
                        </div>
                        <!-- <app-select
                            [idInfo]="'subject'"
                            [label]="'Call Outcome'"
                            [keyName]="{key:'key', name: 'name'}" 
                            [list]="outcome"
                            [options]="{noFirstOption: true}"
                            [value]="form.get('subject')?.value" 
                            (onSave)="form.get('subject')?.setValue($event)"
                        ></app-select> -->
                    </div>
                    <div class="select_cont_item cont_item_btns">
                        <div class="touchpoints">
                            <button
                                class="touchpoint_item" 
                                (click)="onChangeTypes('Email')" 
                                [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('Email')}"
                            >
                                Email
                            </button>
                            <button
                                class="touchpoint_item" 
                                (click)="onChangeTypes('SMS')" 
                                [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('SMS')}"
                            >
                                SMS
                            </button>
                            <button
                                class="touchpoint_item" 
                                (click)="onChangeTypes('Call')" 
                                [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('Call')}"
                            >
                                Call
                            </button>
                        </div>

                        <div class="directions">
                            <button
                                class="touchpoint_item" 
                                (click)=" this.form.get('direction')?.setValue('Inbound')" 
                                [ngClass]="{'active_coin_btn': this.form.get('direction').value == 'Inbound'}"
                            >
                                Inbound
                            </button>
                            <button
                                class="touchpoint_item" 
                                (click)=" this.form.get('direction')?.setValue('Outbound')" 
                                [ngClass]="{'active_coin_btn': this.form.get('direction').value == 'Outbound'}"
                            >
                                Outbound
                            </button>
                        </div>

                        <!-- <app-multi-select
                            [idInfo]="'touchpoint'"
                            [keyName]="{key:'key', name: 'name'}" 
                            [value]="form.get('types')?.value" 
                            [data]="touchpoints"
                            [options]="{required: typesWarning && this.form.get('types')?.invalid}"
                            (onSave)="form.get('types')?.setValue($event)"
                            [label]="'Touchpoint'"
                        ></app-multi-select> -->
                    </div>
                    @if (
                        (typesWarning && !form.get('types')?.value?.length)
                    ) {
                        <p class="please_select">Please select touchpoint type</p>
                    }
                    <!-- <div class="select_cont_item">
                        <app-select
                            [idInfo]="'direction'"
                            [label]="'Direction'"
                            [options]="{noFirstOption: true}"
                            [list]="direction"
                            [value]="form.get('direction')?.value" 
                            (onSave)="form.get('direction')?.setValue($event)"
                        ></app-select>
                    </div> -->
                    <div class="flex items-center select_cont_dates select_cont_item">
                        <app-date
                            id="task-follow-up-date"
                            [isContainerStyle]="true"
                            [options]="{ placeholder: 'Date', right: '90px', required: dateWarning && this.form.get('sentAt')?.invalid }"
                            [parent]="form.value"
                            [time]="true"
                            [startKey]="'sentAt'"
                            (onSave)="form.patchValue({'sentAt': $event})"
                        ></app-date>
                        <!-- <div class="select_cont_date">
                            <app-date 
                                [label]="'Date'"
                                [idInfo]="'date'"
                                [startKey]="'sentAt'"
                                [options]="{required: dateWarning && this.form.get('sentAt')?.invalid}"
                                [parent]="form.value"
                                (onSave)="form.get('sentAt')?.setValue($event?.sentAt)"
                            ></app-date>
                        </div>
                        <div class="select_cont_time">
                            <app-select
                                [idInfo]="'time'"
                                [options]="{noFirstOption: true}"
                                [list]="times"
                                [value]="form.get('sentAt').value | date: 'hh:mm' : 'UTC'" 
                                (onSave)="onSaveTime($event)"
                            ></app-select>
                        </div>
                        <div class="select_cont_am_pm">
                            <app-select
                                [idInfo]="'am-pm'"
                                [options]="{noFirstOption: true}"
                                [list]="timeTypes"
                                [value]="form.get('sentAt').value | date: 'a' : 'UTC'" 
                                (onSave)="onSaveTimeType($event)"
                            ></app-select>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="descr">
                <textarea 
                    id="description" 
                    rows="4" 
                    formControlName="description"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Internal Notes"
                ></textarea>
            </div>
            <div class="btns">
                <button class="cancel" (click)="close()">Cancel</button>
                <button class="log_activity" (click)="send()">Log Activity</button>
            </div>
        </form>
    </div>
</div>